import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import 'array.prototype.at'
import 'intersection-observer'
import mixpanel from 'mixpanel-browser'
import { NextPage } from 'next'
import { DefaultSeo } from 'next-seo'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import queryString from 'query-string'
import { ReactElement, ReactNode, useEffect } from 'react'
import 'scroll-behavior-polyfill'
import store from 'store2'
import { BOOKING_TEST_UTM_EXCLUSIONS } from 'lib/constants'
import { GTM_ID, UtmParams } from 'lib/gtm'
import splitTesting from 'lib/splitTesting'
import theme from 'lib/theme'
import {
  coerceQueryParamToString,
  getIdTokenPayloadFromCookies,
  inferMarketFromUtm,
} from 'lib/utils'
import CookieBanner from 'components/CookieBanner'
import FriendbuyDialog from 'components/FriendbuyDialog'
import SalesforceChatWidget from 'components/SalesforceChatWidget'
import { AppProvider } from 'components/contexts/AppContext'

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  persistence: 'localStorage',
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type MyAppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = (props: MyAppPropsWithLayout) => {
  const { Component, pageProps } = props
  const { web_view } = parseCookies()
  const isWebView = web_view == 'true'

  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  const extractUtmParams = (params: queryString.ParsedQuery<string>) => {
    return {
      utm_source: coerceQueryParamToString(params.utm_source),
      utm_medium: coerceQueryParamToString(params.utm_medium),
      utm_campaign: coerceQueryParamToString(params.utm_campaign),
      utm_term: coerceQueryParamToString(params.utm_term),
      utm_content: coerceQueryParamToString(params.utm_content),
    } as UtmParams
  }

  useEffect(() => {
    // Store UTM params for appointment attribution
    const params = queryString.parse(location.search)
    const utmParams = extractUtmParams(params)

    if (Object.values(utmParams).some((v) => !!v)) {
      store.set('utmParams', utmParams)
    }

    if (params.gclid) {
      store.set('gclid', params.gclid)
    }
  }, [])

  useEffect(() => {
    // Infer market from UTM params
    const params = queryString.parse(location.search)
    const utmParams = extractUtmParams(params)

    const inferredMarket = Object.values(utmParams)
      .map((utmParam) => inferMarketFromUtm(utmParam))
      .find((market) => market !== null)

    if (inferredMarket) {
      sendGTMEvent({
        market: inferredMarket,
      })
    }
  }, [])

  useEffect(() => {
    const { email } = getIdTokenPayloadFromCookies() ?? {}
    mixpanel.register({
      'Logged In': !!email,
    })
  }, [])

  useEffect(() => {
    const cookies = parseCookies()

    const isWebView = cookies.web_view == 'true'
    const isLoggedIn = !!cookies.idToken
    const originalLocation = `${document.location.origin}${document.location.pathname}${document.location.search}`

    sendGTMEvent({
      isWebView,
      isLoggedIn,
      originalLocation,
    })

    if (!isWebView) {
      const params = queryString.parse(location.search)
      const utmParams = extractUtmParams(params)

      if (
        BOOKING_TEST_UTM_EXCLUSIONS.some((exclusion) =>
          exclusion.includes(utmParams?.utm_campaign),
        )
      ) {
        return
      }

      sendGTMEvent({
        event: 'test_info',
        test_name: '11_calendar_in_booking',
        test_cohort: splitTesting.isCalendarBookingTestEnabled(cookies)
          ? 'test'
          : 'control',
      })
    }
  }, [])

  return (
    <AppProvider>
      <GoogleTagManager gtmId={GTM_ID} />
      <SalesforceChatWidget />
      <AppCacheProvider {...props}>
        <Head>
          <title>Tend</title>
          <meta
            name="viewport"
            content={`${
              isWebView ? 'maximum-scale=1' : 'minimum-scale=1'
            }, initial-scale=1, width=device-width`}
          />
          <link
            rel="canonical"
            href={`https://www.hellotend.com${router.asPath
              .split('?')[0]
              .replace('/index', '/')}`}
          />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <DefaultSeo
            title="Tend"
            openGraph={{
              type: 'website',
              locale: 'en_US',
              url: 'https://www.hellotend.com',
              site_name: 'Tend',
            }}
            twitter={{
              handle: '@hellotend',
              site: '@hellotend',
              cardType: 'summary',
            }}
          />
          {getLayout(<Component {...pageProps} />)}
          <FriendbuyDialog
            storeKey="wasDialogShown"
            friendbuyPopupId={
              process.env.NEXT_PUBLIC_CTF_ENTRIES_FRIENDBUY_POPUP
            }
            campaignName="evergreen"
          />
          <FriendbuyDialog
            storeKey="wasBreezyBracesDialogShown"
            friendbuyPopupId={
              process.env.NEXT_PUBLIC_CTF_ENTRIES_FRIENDBUY_BREEZY_BRACES_POPUP
            }
            campaignName="breezybraces"
          />
          <CookieBanner />
        </ThemeProvider>
      </AppCacheProvider>
    </AppProvider>
  )
}

export const reportWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  if (typeof window !== 'undefined' && (window as any).dataLayer) {
    ;(window as any).dataLayer.push({
      event: 'web-vitals',
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      event_action: name,
      event_value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      nonInteraction: true,
    })
  }
}

export default MyApp
