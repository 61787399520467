import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import store from 'store2'
import useSWR from 'swr'
import { route } from 'lib/constants'
import { marketingClient } from 'lib/contentful'
import { nantes } from 'lib/fonts'
import { colors } from 'lib/root'
import { TypeFriendbuyPopupFields } from 'lib/types/ctf-marketing'
import { coerceQueryParamToString } from 'lib/utils'
import TendDialog from 'components/TendDialog'

export type FriendbuyDialogProps = {
  campaignName: string
  friendbuyPopupId: string
  storeKey: string
}

const FriendbuyDialog: FunctionComponent<FriendbuyDialogProps> = ({
  campaignName,
  friendbuyPopupId,
  storeKey,
}) => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const friendbuyStore = store.session.namespace('friendbuy')
  const openDialog = () => {
    if (
      router.pathname === route.referralRedirect ||
      router.pathname === route.bbReferralRedirect
    ) {
      return
    }

    setIsOpen(true)
    friendbuyStore.set(storeKey, true)
  }

  useEffect(() => {
    if (!friendbuyStore.get(storeKey)) {
      queryChecker()
    }

    return () => setIsOpen(false)
  }, [router.pathname, router.query])

  const lowerCaseString = (str: unknown) =>
    typeof str === 'string' ? str.toLowerCase() : ''

  const queryChecker = () => {
    const utm_source = coerceQueryParamToString(router.query.utm_source)
    const utm_campaign = coerceQueryParamToString(router.query.utm_campaign)

    if (!utm_source || !utm_campaign) {
      return
    }

    if (
      lowerCaseString(utm_source) === 'friendbuy' &&
      lowerCaseString(utm_campaign).includes(lowerCaseString(campaignName))
    ) {
      return openDialog()
    }
  }

  const dialogDataFetcher = async () => {
    const entry = await marketingClient.getEntry<TypeFriendbuyPopupFields>(
      friendbuyPopupId,
    )

    return entry.fields
  }

  const { data: fetchedData } = useSWR(
    isOpen ? '/contentful/friendbuyPopup' : undefined,
    dialogDataFetcher,
    { revalidateOnFocus: false },
  )

  if (!fetchedData) {
    return <div />
  }

  return (
    <TendDialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullScreen={false}
    >
      <Grid container>
        <Grid xs={0} sm={6} position="relative">
          <Image
            src={'https:' + fetchedData.image.fields.file.url}
            alt={fetchedData.image.fields.title}
            fill
            style={{ objectFit: 'cover' }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          sx={{
            backgroundColor: colors.defaults.white,
            py: 12,
            px: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: '2rem !important',
              color: colors.core.copper[600],
              pb: 2,
            }}
          >
            {fetchedData.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: nantes.style.fontFamily,
              fontSize: '1.25rem !important',
              color: colors.core.grey[700],
            }}
          >
            {fetchedData.description}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Continue"
            onClick={() => setIsOpen(false)}
            sx={{ width: 160, mt: 5 }}
          >
            {fetchedData.buttonText}
          </Button>
        </Grid>
      </Grid>
    </TendDialog>
  )
}

export default FriendbuyDialog
