import { colors } from 'lib/root'

export const route = {
  home: '/',
  about: '/about',
  booking: '/booking/markets',
  markets: '/booking/markets',
  orthoMarkets: '/booking/ortho-markets',
  services: '/booking/services',
  studios: '/booking/studios',
  email: '/booking/email',
  emergency: '/booking/emergency',
  emergencyType: '/booking/emergency-type',
  emergencyTreatment: '/booking/emergency-treatment',
  emergencyFreeformQuestions: '/booking/freeform-questions',
  insurance: '/booking/insurance',
  insuranceStatus: '/booking/insurance-status',
  procedures: '/booking/procedures',
  cosmetic: '/booking/cosmetic',
  cosmeticConsult: '/booking/cosmetic-consult',
  times: '/booking/times',
  contactInfo: '/booking/contact-info',
  paymentInfo: '/booking/payment-info',
  bookingConfirmation: '/booking/confirmation',
  breezyBracesHome: '/booking/breezy-braces',
  breezyBracesHowItWorks: '/breezy-braces/how-it-works',
  breezyBracesPricing: '/breezy-braces/pricing',
  breezyBracesResults: '/breezy-braces/results',
  breezyBracesOrthodontists: '/breezy-braces/orthodontists',
  breezyBracesLocations: '/breezy-braces/studios',
  breezyBracesFAQ: '/breezy-braces/faq',
  intake: '/intake',
  intakeInsuranceProvider: '/intake/insurance/provider',
  intakePersonalMedicalConditions: '/intake/personal/medical-conditions',
  intakeConfirmation: '/intake/confirmation',
  intakeInsuranceResults: '/intake/insurance/results',
  intakeInsuranceCard: '/intake/insurance/card',
  breezyBraces: '/breezy-braces',
  breezyBracesConsult: '/booking/breezy-braces-consult',
  invisalignConsult: '/booking/invisalign-consult',
  smileStraighteningConsult: '/booking/smile-straightening-consult',
  bookingSmileStraightening: '/booking/smile-straightening',
  orthodontics: '/orthodontics',
  invisalign: '/invisalign',
  leadership: '/leadership',
  signup: '/signup',
  signupComplete: '/signup/complete',
  signupCreate: '/signup/create',
  emailVerification: '/email-verification',
  login: '/login',
  loginPassword: '/login/password',
  referralRedirect: '/referral-redirect',
  bbReferralRedirect: '/breezy-braces-referral-redirect',
  profile: '/profile',
  profileBreezyBraces: '/profile/breezy-braces',
  myMouth: '/profile/health',
  visitDetail: '/profile/appointments/[token]',
  locations: '/studios',
  invite: '/invite-a-friend',
  orthoQuiz: '/booking/ortho-quiz',
  orthoQuizResult: '/booking/ortho-quiz-result',
  confirmEmailChange: '/confirm-email-change',
  forgotPassword: '/forgot-password',
  forgotPasswordSent: '/forgot-password/sent',
  setPassword: '/set-password',
  setPasswordSuccess: '/set-password/success',
}

export const CAREERS_URL = '/careers'

// size in rems
export const iconSizes = {
  lg: '3',
  md: '2.5',
  sm: '2',
  xs: '1.5',
}

export const iconColors = {
  light: colors.core.paper,
  dark: colors.core.grey[700],
  inherit: 'currentColor',
}

export const creditCardWidth = 327
export const creditCardHeight = 196

export const primaryAddressCardWidth = 315
export const primaryAddressCardHeight = 204
export const otherAddressCardWidth = 315
export const otherAddressCardHeight = 177

export const ONE_DAY = 60 * 60 * 24
export const ONE_WEEK = ONE_DAY * 7
export const ONE_YEAR = ONE_DAY * 365
export const FIVE_YEARS = ONE_YEAR * 5

export const INSURANCE_DISCLAIMER = `Unfortunately, there are some dental insurance plans we don’t accept, including Medicaid, Medicare Advantage, Healthplex, HMOs, DMOs, and others. If we don’t take yours, we’ll contact you ahead of your visit.`

export const INVALID_PHONE_ERROR_CODE = 'invalid'
export const INVALID_EMAIL_ERROR_CODE = 'conflict'
export const AVAILABILITY_CONFLICT_ERROR_CODE = 'availability_conflict'
export const GENERIC_SERVER_ERROR_CODE = 'generic_error'
export const HAS_SRP_TX_PLAN_BOOKING_HYGIENE_ERROR = 'has_srp_tx_plan'
export const INSUFFICIENT_FUNDS_ERROR_CODE = 'insufficent_funds'
export const DISCOUNT_HEADER_HEIGHT = 58

export const REVIEW_RATING = 5

export const CONDENSED_BOOKING_FLOW_TEST_FLAG = 'condensed_booking_flow_test'
export const CALENDAR_BOOKING_TEST_FLAG = 'calendar_booking_test'

export const BOOKING_TEST_UTM_EXCLUSIONS = [
  'profile',
  'previsit',
  'post-visit',
  'PostVisit',
  'InsVerification',
  'NPS',
]

export const GEO_REGION = 'geo_region'

export const FIRST_EXAM_PRICE = 375
export const WHITENING_PRICE = 495
export const EMERGENCY_PRICE = 215

export const CANCELLATION_FEE = 50
export const TX_CANCELLATION_FEE = 100

export const NO_SHOW_FEE = 100
export const TX_NO_SHOW_FEE = 250

export const SAME_DAY_BOOKING_CUTOFF_HOURS = 2
export const EMERGENCY_BOOKING_CUTOFF_HOURS = 72

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/tend-dental/id1563915028'

export const SIGNUP_EMAIL_KEY = 'signupEmail'
export const VERIFICATION_EMAIL_KEY = 'verificationEmail'
export const LOGIN_EMAIL_KEY = 'loginEmail'
export const FORGOT_PASSWORD_EMAIL_KEY = 'forgotPasswordEmail'
