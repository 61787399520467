import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import store from 'store2'

interface AppContextProps {
  mobileMenuIsShown: boolean
  headerHeight: number
  bannerHeight: number
  toggleMobileMenuIsShown: () => void
  pageLoading: boolean
  setPageLoading: Dispatch<SetStateAction<boolean>>
  setHeaderHeight: Dispatch<SetStateAction<number>>
  setBannerHeight: Dispatch<SetStateAction<number>>
  email: string
  isWebView: boolean
  userEmail: string
  setUserEmail: Dispatch<SetStateAction<string>>
}

export const AppContext = createContext<AppContextProps>(null)

export const AppProvider = ({ children }) => {
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [bannerHeight, setBannerHeight] = useState(0)
  const [isWebView, setIsWebView] = useState(null)
  const [userEmail, setUserEmail] = useState('')
  const email = 'hello@hellotend.com'
  const cookies = parseCookies()
  const { web_view } = cookies
  const router = useRouter()
  const query = router.query

  useEffect(() => {
    setIsWebView(router.route.includes('webview') || web_view === 'true')
  }, [web_view, router.route])

  useEffect(() => {
    if (query.fbuy_ref_code) {
      store.set('friendbuy_referral_code', query.fbuy_ref_code, true)
    } else if (query.referralCode) {
      store.set('friendbuy_referral_code', query.referralCode, true)
      store.set('friendbuy_referral_version', 'v2', true)
    }
  }, [query])

  const toggleMobileMenuIsShown = () => {
    setMobileMenuIsShown(!mobileMenuIsShown)
  }

  return (
    <AppContext.Provider
      value={{
        mobileMenuIsShown,
        toggleMobileMenuIsShown,
        pageLoading,
        setPageLoading,
        email,
        setHeaderHeight,
        setBannerHeight,
        headerHeight,
        bannerHeight,
        isWebView,
        userEmail,
        setUserEmail,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const AppContextConsumer = AppContext.Consumer
