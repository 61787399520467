import { Box, Button, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import store from 'store2'
import { colors } from 'lib/root'
import Link from './Link'
import { AppContext } from './contexts/AppContext'

export const DISMISSED_KEY = 'cookieBannerDismissed'

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(true)
  const { isWebView } = useContext(AppContext)

  useEffect(() => {
    const dismissed = store.get(DISMISSED_KEY)
    if (dismissed || isWebView) {
      setIsVisible(false)
    }
  }, [isWebView])

  const dismissBanner = () => {
    store.set(DISMISSED_KEY, true)
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <Box
      sx={{
        display: { md: 'flex' },
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 'tooltip',
        bgcolor: colors.core.green[200],
        pt: 2,
        pb: { xs: 3, md: 2 },
        px: 4.5,
        mx: 'auto',
      }}
    >
      <Typography
        sx={{
          fontSize: '1rem !important',
          lineHeight: 1.3,
          color: colors.core.green[700],
          mb: { xs: 2, md: 0 },
          '& a': {
            color: colors.core.green[700],
            fontWeight: 700,
            textDecoration: 'underline',
            '&:hover': {
              color: colors.core.green[600],
            },
            '&:active': {
              color: colors.core.green[500],
            },
          },
        }}
      >
        By using this website, you agree with our use of cookies. To learn more
        read our <Link href="/terms#cookies">Cookie Policy</Link>.
      </Typography>
      <Box sx={{ width: { xs: 1, md: 'unset' } }}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{
            color: 'common.white',
            bgcolor: colors.core.green[700],
            '&:hover': {
              color: colors.defaults.white,
              bgcolor: colors.core.green[600],
            },
            '&:active': {
              color: colors.defaults.white,
              bgcolor: colors.core.green[500],
            },
          }}
          onClick={dismissBanner}
        >
          Dismiss
        </Button>
      </Box>
    </Box>
  )
}

export default CookieBanner
