import { alpha, createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import defaultsDeep from 'lodash.defaultsdeep'
import zIndexes from 'lib/zIndexes'
import { foundersGrotesk, nantes } from './fonts'
import { colors } from './root'

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1600,
  },
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary']
  }
  interface PaletteOptions {
    default: PaletteOptions['primary']
  }
}

const theme = createTheme({ breakpoints })

export const lightTheme = createTheme({
  breakpoints,
  palette: {
    mode: 'light',
    primary: {
      main: '#698680',
      contrastText: '#f9f9f6',
    },
    secondary: {
      main: colors.core.grey[700],
      contrastText: '#f9f9f6',
    },
    error: {
      main: '#c24a33',
    },
    text: {
      primary: colors.core.grey[700],
      secondary: '#5e7872',
    },
    background: {
      default: '#f9f9f6',
    },
    common: {
      white: '#f9f9f6',
    },
    default: {
      main: grey[300],
      dark: '#d5d5d5',
    },
  },
  typography: {
    fontFamily: foundersGrotesk.style.fontFamily,
    h1: {
      fontFamily: nantes.style.fontFamily,
      lineHeight: 1.2,
      fontWeight: '700',
      fontSize: '4rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '6rem',
      },
    },
    h2: {
      fontFamily: nantes.style.fontFamily,
      lineHeight: 1.2,
      fontWeight: '700',
      fontSize: '3rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
    },
    h3: {
      fontFamily: nantes.style.fontFamily,
      lineHeight: 1.2,
      fontWeight: '700',
      fontSize: '2rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
    },
    h4: {
      fontFamily: nantes.style.fontFamily,
      lineHeight: 1.2,
      fontWeight: '700',
      fontSize: '1.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
    h5: {
      fontFamily: nantes.style.fontFamily,
    },
    body1: {
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    body2: {
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    overline: {
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.12em',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          -moz-osx-font-smoothing: auto;
          font-variant-ligatures: discretionary-ligatures;
        }

        body {
          height: 100vh;
        }

        a {
          color: #0068b5;
          background-color: #f9f9f6;
        }

        .gm-style {
          color: #000 !important;
        }
      `,
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible, &:focus-visible': {
            outline: [
              'Highlight auto 5px',
              '-webkit-focus-ring-color auto 5px',
            ],
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'default' },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { variant: 'text', color: 'default' },
          style: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          fontWeight: 500,
          letterSpacing: '0.12em',
          borderRadius: '100px',
          padding: '12px 32px',
          '&:hover': {
            textDecoration: 'none',
          },
        },
        sizeLarge: {
          fontSize: '1.125rem',
          padding: '16px 40px',
        },
        containedPrimary: {
          color: '#f9f9f6',
          backgroundColor: '#0068b5',
          '&:hover': {
            color: '#ffece9',
            backgroundColor: '#0060a7',
            '@media (hover: none)': {
              color: '#ffece9',
              backgroundColor: '#0060a7',
            },
          },
          '&:active': {
            color: '#ffb6a8',
            backgroundColor: '#00589a',
          },
          '&:disabled, &.Mui-disabled': {
            color: alpha(colors.core.grey[800], 0.3),
            backgroundColor: alpha(colors.core.grey[800], 0.1),
          },
        },
        containedSecondary: {
          color: colors.defaults.white,
          backgroundColor: '#9C6C41',
          '&:hover': {
            color: colors.defaults.white,
            backgroundColor: '#97683E',
            '@media (hover: none)': {
              color: colors.defaults.white,
              backgroundColor: '#97683E',
            },
          },
          '&:active': {
            color: '#ffded9',
            backgroundColor: '#7b5431',
          },
        },
        outlinedPrimary: {
          color: colors.core.blue[800],
          border: `1px solid ${colors.core.blue[800]}`,
          '&:hover': {
            color: '#f9f9f6',
            backgroundColor: '#0060a7',
            borderColor: '#0060a7',
            '@media (hover: none)': {
              color: '#f9f9f6',
              backgroundColor: '#0060a7',
              borderColor: '#0060a7',
            },
          },
          '&:active': {
            color: '#ffb6a8',
            backgroundColor: '#00589a',
          },
          '&:disabled, &.Mui-disabled': {
            color: colors.core.grey[800],
            borderColor: colors.core.grey[800],
            opacity: 0.38,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f9f9f6',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: alpha(colors.core.grey[700], 0.5),
          '&.Mui-checked': {
            color: colors.core.grey[700],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: colors.core.grey[800],
        },
        colorSuccess: {
          backgroundColor: colors.core.green[300],
          color: colors.core.green[800],
          '& .MuiChip-icon': {
            color: colors.core.green[800],
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(!ownerState.disableGutters && {
            [theme.breakpoints.between('xs', 'sm')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(8),
              paddingRight: theme.spacing(8),
            },
          }),
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.9375rem',
          color: colors.core.paper,
          lineHeight: 1.2,
          [theme.breakpoints.up('md')]: {
            fontSize: '1.125rem',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        colorSecondary: {
          color: colors.core.grey[700],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          outlineColor: 'rgba(0, 0, 0, 0.1)',
          transition: 'outline-color 200ms ease-out',
          '&:focus': {
            outline: ['Highlight auto 3px', `#E7E4E0 auto 3px`],
            paddingLeft: '4px',
          },
          '&:focus-visible': {
            transition: 'outline-color 200ms ease-in',
          },
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: colors.core.grey[700],
          '&:disabled, .Mui-disabled': {
            color: colors.core.grey[700],
            opacity: 0.38,
          },
          '& input:read-only': {
            opacity: 0.38,
          },
        },
        input: {
          fontSize: '1.25rem',
        },
        underline: {
          '&:after': {
            borderBottomColor: colors.core.grey[700],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
          },
          color: colors.core.grey[700],
          '&.Mui-focused': {
            color: colors.core.grey[700],
          },
          '&:disabled, &.Mui-disabled': {
            color: colors.core.grey[700],
          },
        },
        shrink: {
          zIndex: zIndexes.standard,
          top: '-4px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.core.grey[700],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
        },
        colorPrimary: {
          backgroundColor: 'transparent',
        },
        barColorPrimary: {
          backgroundColor: colors.core.blue[800],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.core.blue[800],
          backgroundColor: 'transparent',
        },
      },
      defaultProps: {
        underline: 'hover',
        color: '#0068b5',
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.core.blue[800],
            backgroundColor: 'unset',
            borderBottomColor: colors.core.blue[800],
            '& h4, p, svg, span, input': {
              color: colors.core.blue[800],
            },
            '& circle, path': {
              stroke: colors.core.blue[800],
            },
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        },
        divider: {
          borderBottomColor: colors.core.grey[400],
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: colors.core.grey[700],
          borderBottomColor: colors.core.grey[400],
          '&:hover': {
            color: colors.core.blue[800],
            backgroundColor: 'unset',
            borderBottomColor: colors.core.blue[800],
            '& h4, p, svg, span, input': {
              color: colors.core.blue[800],
            },
          },
          '&:active': {
            color: colors.core.blue[875],
            backgroundColor: 'unset',
            borderBottomColor: colors.core.blue[875],
            '& h4, p, svg, span, input': {
              color: colors.core.blue[875],
            },
          },
          '&.Mui-selected': {
            color: colors.core.blue[800],
            backgroundColor: 'unset',
            borderBottomColor: colors.core.blue[800],
            '& h4, p, svg, span, input': {
              color: colors.core.blue[800],
            },
            '& circle, path': {
              stroke: colors.core.blue[800],
            },
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0 !important',
        },
        list: {
          backgroundColor: colors.core.blue[800],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.core.paper,
          borderRadius: 0,
          '&:hover': {
            backgroundColor: colors.core.paper,
            color: colors.core.blue[800],
          },
          '&.Mui-selected': {
            backgroundColor: '#fff',
            color: colors.core.blue[800],
            '&:hover': {
              backgroundColor: colors.core.paper,
              color: colors.core.blue[800],
            },
            '&.Mui-selected.Mui-focusVisible': {
              backgroundColor: '#fff',
            },
          },
        },
        gutters: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: colors.core.grey[700],
          lineHeight: 1.5,
          '&:before, &:after': {
            borderBottomColor: colors.core.grey[700],
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          color: 'currentColor',
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: colors.core.grey[800],
        },
        message: {
          fontSize: '1rem',
          textTransform: 'uppercase',
          letterSpacing: '0.12em',
          fontWeight: 500,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          lineHeight: 1.75,
          '&.Mui-selected': {
            color: 'inherit',
          },
        },
        textColorPrimary: {
          color: colors.core.grey[700],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.core.grey[700],
          height: 3,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          color: colors.core.paper,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.core.green[500],
        },
        head: {
          borderBottomWidth: 2.36,
        },
        body: {
          borderBottomWidth: 0.39,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: '#0068b5',
          color: '#f9f9f6',
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: '#f9f9f6 !important',
            color: '#0068b5',
          },
        },
        option: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        paper: {
          borderRadius: 0,
          margin: 0,
        },
        tag: {
          borderRadius: 0,
          fontSize: '1.25rem',
        },
        input: {
          '&:focus': {
            paddingLeft: '4px',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.25rem !important',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        message: {
          padding: 0,
        },
        standardError: {
          backgroundColor: colors.utility.red[100],
          color: colors.utility.red[700],
          '& .MuiAlert-icon': {
            color: colors.utility.red[700],
          },
        },
        standardSuccess: {
          backgroundColor: colors.core.green[200],
          color: colors.core.green[700],
          '& .MuiAlert-icon': {
            color: colors.core.green[700],
          },
        },
      },
    },
  },
})

export const darkTheme = createTheme(
  defaultsDeep(
    {
      palette: {
        mode: 'dark',
        text: {
          primary: '#f9f9f6',
          secondary: '#efede9',
        },
        error: {
          main: '#ffcac0',
        },
        background: {
          default: colors.core.green[600],
        },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: colors.core.green[700],
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            listbox: {
              backgroundColor: '#F9F9F6',
              color: '#0068B5',
              '& .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: `${colors.core.blue[800]} !important`,
                color: colors.core.paper,
              },
            },
            noOptions: {
              color: '#0068B5',
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              '&.Mui-focusVisible, &:focus-visible': {
                outline: [
                  'Highlight auto 5px',
                  '-webkit-focus-ring-color auto 5px',
                ],
              },
            },
          },
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              color: '#0068B5',
              backgroundColor: '#f9f9f6',
              '&:hover': {
                color: '#0060A7',
                backgroundColor: '#fff',
                '@media (hover: none)': {
                  color: '#0060A7',
                  backgroundColor: '#fff',
                },
              },
              '&:active': {
                color: '#00589a',
                backgroundColor: '#efede9',
              },
              '&:disabled': {
                color: '#e5e2dc',
                backgroundColor: '#f9f9f6',
                opacity: 0.38,
              },
            },
            outlinedPrimary: {
              color: '#f9f9f6',
              border: '1px solid #f9f9f6',
              '&:hover': {
                color: '#0068b5',
                backgroundColor: '#fff',
                '@media (hover: none)': {
                  color: '#0068b5',
                  backgroundColor: '#fff',
                },
              },
              '&:active': {
                color: '#00589a',
                backgroundColor: '#efede9',
              },
              '&:disabled, &.Mui-disabled': {
                color: colors.core.paper,
                borderColor: colors.core.paper,
                opacity: 0.38,
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            underlineNone: {
              color: '#0068B6',
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              color: colors.core.paper,
              '&:disabled, .Mui-disabled': {
                color: colors.core.paper,
                opacity: 0.38,
              },
            },
            input: {
              '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
                {
                  WebkitTextFillColor: colors.core.paper,
                },
            },
            underline: {
              '&:after': {
                borderBottomColor: colors.core.paper,
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: '1.25rem',
              color: colors.core.paper,
              '&.Mui-focused': {
                color: colors.core.paper,
              },
              '&:disabled, &.Mui-disabled': {
                color: colors.core.paper,
              },
            },
            shrink: {
              zIndex: zIndexes.standard,
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: colors.core.paper,
            },
          },
        },
        MuiCircularProgress: {
          styleOverrides: {
            colorPrimary: {
              color: colors.core.paper,
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              color: colors.core.paper,
              borderTopColor: colors.core.green[500],
              '&:hover': {
                color: colors.defaults.white,
                textDecoration: 'underline',
                '& h4, p, svg, span, input': {
                  color: colors.defaults.white,
                },
              },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              borderRadius: '0 !important',
            },
            list: {
              backgroundColor: colors.core.paper,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              color: colors.core.blue[800],
              borderRadius: 0,
              '&:hover': {
                backgroundColor: colors.core.blue[800],
                color: colors.core.paper,
              },
              '&.Mui-selected': {
                color: colors.core.paper,
                backgroundColor: colors.core.blue[800],
                '&:hover': {
                  backgroundColor: colors.core.blue[800],
                  color: colors.core.paper,
                },
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              color: colors.core.paper,
              '&:before, &:after': {
                borderBottomColor: colors.core.paper,
              },
            },
          },
        },
      },
    },
    lightTheme,
  ),
)

export default lightTheme
