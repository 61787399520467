import {
  alpha,
  Dialog,
  DialogProps,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { colors } from 'lib/root'
import { toSxArray } from 'lib/utils'
import zIndexes from 'lib/zIndexes'
import CloseIcon from './icons/CloseIcon'
import LeftLongArrow from './icons/LeftLongArrow'

export interface TendDialogProps
  extends Omit<
    DialogProps,
    'componentsProps' | 'PaperProps' | 'className' | 'classes' | 'BackdropProps'
  > {
  onClose?: () => void
  closeButton?: boolean
  backButton?: boolean
  modal?: boolean
  styles?: {
    closeButtonSx?: SxProps<Theme>
    backButtonSx?: SxProps<Theme>
    paperSx?: SxProps<Theme>
    backdropSx?: SxProps<Theme>
    rootSx?: SxProps<Theme>
  }
}

const getSxValuesArray = (
  value: SxProps<Theme>,
  selector: string,
): SxProps<Theme>[] =>
  (Array.isArray(value) ? value : [value]).map((v) => ({
    [selector]: v,
  }))

const TendDialog: FunctionComponent<TendDialogProps> = ({
  children,
  onClose = () => void 0,
  closeButton = true,
  backButton = false,
  modal = false,
  styles = {},
  sx = [],
  ...other
}) => {
  const {
    closeButtonSx = [],
    backButtonSx = [],
    backdropSx = [],
    paperSx = [],
    rootSx = [],
  } = styles

  const router = useRouter()
  const {
    query: { notchOffset },
  } = router

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      onClose={modal ? undefined : onClose}
      disableEscapeKeyDown={modal}
      role="dialog"
      fullScreen={fullScreen}
      sx={[
        {
          ...(!modal && {
            '& .MuiDialog-container': { '&:hover': { cursor: 'pointer' } },
          }),
          '& .MuiBackdrop-root': {
            backgroundColor: alpha(colors.core.green[600], 0.9),
          },
          '& .MuiPaper-root:not(.MuiAlert-root)': {
            width: 1,
            borderRadius: 0,
            overflow: 'auto',
            paddingTop: notchOffset ? notchOffset : 0,
            '&:hover': { cursor: 'auto' },
          },
          '& .MuiAlert-root': {
            py: 0.5,
            mb: 2,
          },
        },
        ...getSxValuesArray(rootSx, '& .MuiDialog-container'),
        ...getSxValuesArray(backdropSx, '& .MuiBackdrop-root'),
        ...getSxValuesArray(paperSx, '& .MuiPaper-root:not(.MuiAlert-root)'),
        ...toSxArray(sx),
      ]}
      {...other}
    >
      {closeButton && (
        <IconButton
          size="small"
          aria-label="Close dialog"
          onClick={onClose}
          sx={[
            {
              position: 'absolute',
              right: 0,
              top: notchOffset ? notchOffset : 0,
              color: colors.core.grey[700],
              fontSize: '3rem',
              zIndex: zIndexes.header,
            },
            ...toSxArray(closeButtonSx),
          ]}
        >
          <CloseIcon fontSize="inherit" color="inherit" />
        </IconButton>
      )}
      {backButton && (
        <IconButton
          size="small"
          aria-label="Close"
          onClick={onClose}
          sx={[
            {
              position: 'absolute',
              left: 0,
              top: 0,
              color: colors.core.grey[700],
              fontSize: '3rem',
              zIndex: zIndexes.header,
            },
            ...toSxArray(backButtonSx),
          ]}
        >
          <LeftLongArrow size="lg" />
        </IconButton>
      )}
      {children}
    </Dialog>
  )
}

export default TendDialog
