import {
  CALENDAR_BOOKING_TEST_FLAG,
  CONDENSED_BOOKING_FLOW_TEST_FLAG,
} from './constants'
import { flags } from './env'
import { TypeSplitTestingFields } from './types/ctf-marketing'

const DEFAULT_THRESHOLD = 50

export type SplitTestingValues = Required<TypeSplitTestingFields>

const defaultSplitTestingValues: SplitTestingValues = {
  condensedBookingFlowTest: 0,
  calendarBookingTest: 0,
}

const getSplitTestingManager = () => {
  const splitTestingValues = process.env
    .splitTesting as unknown as TypeSplitTestingFields

  const values: SplitTestingValues = {
    ...defaultSplitTestingValues,
    ...(splitTestingValues ?? {}),
  }

  const generateValue = (key?: keyof SplitTestingValues): boolean => {
    const threshold = key ? values[key] : DEFAULT_THRESHOLD

    return Math.random() * 100 < threshold
  }

  const isTestEnabled = (
    cookies: Record<string, string>,
    key: string,
    flagKey?: keyof typeof flags,
  ): boolean => {
    const isEnabledInCookie = cookies[key] === 'true'
    const isFlagEnabled = flagKey ? flags[flagKey] : true

    return isFlagEnabled && isEnabledInCookie
  }

  const isCondensedBookingFlowTestEnabled = (
    cookies: Record<string, string>,
  ): boolean => {
    const isWebView = cookies.web_view === 'true'
    return (
      isTestEnabled(cookies, CONDENSED_BOOKING_FLOW_TEST_FLAG) && !isWebView
    )
  }

  const isCalendarBookingTestEnabled = (
    cookies: Record<string, string>,
  ): boolean => {
    const isWebView = cookies.web_view === 'true'
    return isTestEnabled(cookies, CALENDAR_BOOKING_TEST_FLAG) && !isWebView
  }

  return {
    generateValue,
    isCondensedBookingFlowTestEnabled,
    isCalendarBookingTestEnabled,
  }
}

const manager = getSplitTestingManager()

export default manager
