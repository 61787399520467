import { SvgIcon } from '@mui/material'
import { iconColors, iconSizes } from 'lib/constants'
import { colors } from 'lib/root'

const LeftLongArrow = (props) => {
  const stroke = iconColors[props.color || 'inherit'] || colors.core.grey[700]

  return (
    <SvgIcon
      viewBox="0 0 40 40"
      {...props}
      sx={{
        fill: 'none',
        fontSize: `${iconSizes[props.size || 'xs']}rem !important`,
        transform: props.rotate ? `rotate(${props.rotate}deg)` : 'rotate(0)',
      }}
    >
      <path
        d="M16.667 15L11.667 20L16.667 25"
        stroke={stroke}
        strokeWidth={props.strokeWidth || 1.5}
      />
      <path
        d="M12.084 20H27.9173"
        stroke={stroke}
        strokeWidth={props.strokeWidth || 1.5}
      />
    </SvgIcon>
  )
}

export default LeftLongArrow
