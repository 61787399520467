import { capitalize, SxProps, Theme } from '@mui/material'
import * as Sentry from '@sentry/nextjs'
import {
  add,
  differenceInCalendarDays,
  format,
  isWithinInterval,
} from 'date-fns'
import { formatInTimeZone, OptionsWithTZ, utcToZonedTime } from 'date-fns-tz'
import { jwtDecode } from 'jwt-decode'
import { NextApiRequest, NextPageContext } from 'next'
import { parseCookies } from 'nookies'
import queryString from 'query-string'
import { Appointment } from './api/appointments'
import { InsuranceProvider } from './api/insuranceProviders'
import { IntakeData } from './api/intake'
import { PatientPreference } from './api/patient-preferences'
import { Patient, PatientType } from './api/patients'
import { IdTokenPayload } from './auth'
import { route } from './constants'
import { AppMessagePayload, ServiceCode, Url } from './types'
import { TypeClinicalTeamMemberFields } from './types/ctf-marketing'
import { TypeProviderFields } from './types/ctf-onboarding'

export const GUARDIAN_PROVIDER_SLUG = 'GUARDIAN'
export const METLIFE_PROVIDER_SLUG = 'METLIFE'

const STUDIO_TIME_ZONES =
  typeof process.env.studioTimeZones === 'string'
    ? JSON.parse(process.env.studioTimeZones)
    : process.env.studioTimeZones

export const getStudioTimeZone = (studioSlug: string) => {
  const timeZone = STUDIO_TIME_ZONES?.[studioSlug]

  if (studioSlug && !timeZone) {
    Sentry.captureMessage(`Missing timezone for studio ${studioSlug}`)
  }

  return timeZone ?? 'America/New_York'
}

export const toStudioTime = (
  date: string | number | Date,
  studioSlug: string | null,
) => {
  const timeZone = getStudioTimeZone(studioSlug)

  return utcToZonedTime(date, timeZone)
}

export const formatStudioTime = (
  date: string | number | Date,
  studioSlug: string,
  formatStr: string,
  options?: OptionsWithTZ,
): string => {
  const timeZone = getStudioTimeZone(studioSlug)

  return formatInTimeZone(date, timeZone, formatStr, options)
}

export const findMatchingInsurer = (
  providers: TypeProviderFields[],
  insurer: string,
) => {
  if (!insurer) {
    return null
  }

  return providers.find((m) => m.slug === insurer)
}

export const insurerRequiresPlanNumber = (insurer: InsuranceProvider) =>
  insurer?.slug === GUARDIAN_PROVIDER_SLUG

export const insurerUsesSsnMemberId = (insurer: InsuranceProvider) =>
  insurer?.slug === METLIFE_PROVIDER_SLUG

export const validatePhoneNumber = (value: string): boolean => {
  const number = value.match(/\d/g)

  if (number) {
    return number.length === 10
  }

  return false
}

export const convertToDate = (dateStr: string) => {
  // Convert date string to date object with local timezone offset
  if (!dateStr) {
    return null
  }
  const date = new Date(dateStr)
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
}

export const formatDateOfBirth = (dateStr: string) =>
  dateStr ? format(new Date(dateStr), 'yyyy-MM-dd') : null

export const removeHyphens = (inputString: string) =>
  inputString.replace(/-/g, '')

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const isConsult = (code: ServiceCode) => {
  const codes = ['BRGCON', 'CRNCON', 'COSCON', 'IMPCON', 'BOTOXCON', 'VENCONS']

  return codes.includes(code)
}

export const hasPreferences = (
  preferences: PatientPreference[],
  service: ServiceCode,
) => {
  const hasFlavor = preferences.find((f) => f.name === 'FLAVOR')
  const hasShow = preferences.find((s) => s.name === 'NETFLIX')

  return service === 'WHTNG' ? hasShow : hasShow && hasFlavor
}

export const getIntakeUrlObject = (
  token: string,
  fastIntake = false,
  sendToInsurance = false,
) => {
  let pathname = route.intake

  if (fastIntake) {
    pathname = sendToInsurance
      ? route.intakeInsuranceProvider
      : route.intakePersonalMedicalConditions
  }

  const query = {
    token,
    appointmentBooked: fastIntake ? true : undefined,
  }

  return { pathname, query }
}

export const getIntakeUrl = (
  token: string,
  fastIntake = false,
  insurance = false,
) => {
  const urlObject = getIntakeUrlObject(token, fastIntake, insurance)

  return queryString.stringifyUrl({
    url: urlObject.pathname,
    query: urlObject.query,
  })
}

export const toUSDString = (amount: number | string, hideZeroCents = true) => {
  const numberAmount = Number(amount)

  if (Number.isNaN(numberAmount)) {
    return 'N/A'
  }

  let result = numberAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (hideZeroCents) {
    result = result.replace('.00', '')
  }

  return result
}

export const isExternalLink = (href: Url) => {
  return typeof href === 'string'
    ? href.startsWith('http')
    : href?.pathname
      ? href.pathname.startsWith('http')
      : false
}

export const isAnchorLink = (href: Url) => {
  return typeof href === 'string'
    ? href.startsWith('#')
    : href?.pathname
      ? href.pathname.startsWith('#')
      : false
}

export const getCreditCardBrandForDisplay = (brand: string) => {
  switch (brand) {
    case 'diners':
      return 'Diners Club'
    case 'jcb':
      return 'JCB'
    case 'unionpay':
      return 'UnionPay'
    case 'amex':
      return 'American Express'
    case 'visa':
    case 'mastercard':
    case 'discover':
      return capitalize(brand)
    default:
      return 'Card'
  }
}

const marketSlugList = [
  { marketName: 'washington-dc', regex: 'DC' },
  { marketName: 'new-york-city', regex: 'NYC' },
  { marketName: 'boston', regex: 'BOS' },
  { marketName: 'atlanta', regex: 'ATL' },
  { marketName: 'nashville', regex: 'NASH' },
]

export const inferMarketFromUtm = (testStr: string) => {
  if (typeof testStr !== 'string') {
    return null
  }

  for (const marketSlug of marketSlugList) {
    const reg = new RegExp(marketSlug.regex, 'i')
    if (reg.test(testStr)) {
      return marketSlug.marketName
    }
  }
  return null
}

export const getInviteDestination = (
  originalDestination: string,
  isLoggedIn: boolean,
): string => {
  if (!isLoggedIn) {
    return `/${originalDestination}`
  }
  return `/profile/${originalDestination}`
}

export const shouldFinishPaperwork = (intakeData: IntakeData) => {
  const serviceType = intakeData.service_type
  if (
    (serviceType === 'CLNCHK' || serviceType === 'WHTNG') &&
    !hasPreferences(intakeData.patient.preferences, serviceType)
  ) {
    return true
  }

  if (intakeData.needs_insurance) {
    return true
  }

  return false
}

export const isIOS = () => {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i, /AppleWebKit/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const mobileBrowser = () => {
  if (isIOS()) {
    return true
  }

  const toMatch = [/Android/i, /webOS/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const isAppointmentConfirmed = (appointment: Appointment | IntakeData) =>
  appointment?.status === 'CONFIRMED'

export const isNotBoston = (marketSlug: string) => marketSlug !== 'boston'

export const transformAlternatePlanName = (name: string) => {
  return name.match(/\d+/g).join('')
}

export const getSpacingNumber = (theme: Theme, value: number) =>
  Number(theme.spacing(value).slice(0, -2))

export const sortByRole = (
  { role: roleA }: TypeClinicalTeamMemberFields,
  { role: roleB }: TypeClinicalTeamMemberFields,
) => {
  if (roleA === 'Dentist' || roleB === 'Dentist') {
    return Number(roleB === 'Dentist') - Number(roleA === 'Dentist')
  } else if (roleA === 'Orthodontist' || roleB === 'Orthodontist')
    return Number(roleB === 'Orthodontist') - Number(roleA === 'Orthodontist')
}

export const sortDescendingByDate = (a, b) =>
  new Date(b.created_at).getTime() - new Date(a.created_at).getTime()

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return ''
  }

  if (phoneNumber.startsWith('+1')) {
    phoneNumber = phoneNumber.slice(2)
  }

  const cleaned = ('' + phoneNumber).replace(/\D/g, '')

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return null
}

export const hasOsteoporosisCondition = (conditions: string[]): boolean =>
  conditions.some((value) => value.toLowerCase().includes('osteoporosis'))

export const getIdTokenPayloadFromCookies = (
  context?:
    | Pick<NextPageContext, 'req'>
    | {
        req: NextApiRequest
      },
): IdTokenPayload | null => {
  const { idToken } = parseCookies(context)

  return !!idToken ? jwtDecode<IdTokenPayload>(idToken) : null
}

export const splitArrayByTwo = <T>(
  values: T[],
  splitIndex?: number,
): [T[], T[]] => {
  const index = splitIndex ?? Math.ceil(values.length / 2)

  return [values.slice(0, index), values.slice(index)]
}

export const coerceQueryParamToString = (param: string | string[]) =>
  Array.isArray(param) ? param.at(-1) : param

export const isFastIntakeEligible = (
  serviceCode: ServiceCode,
  patientType: PatientType,
) => serviceCode === 'CLNCHK' && patientType === 'NEW'

export const sendMessageToApp = (payload: AppMessagePayload): void => {
  //@ts-ignores
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(payload))
}

export const toSxArray = (sx: SxProps<Theme> = []) =>
  Array.isArray(sx) ? sx : [sx]

export const random = (min = 0, max = 1): number =>
  min + Math.floor(Math.random() * (max - min + 1))

export const lastMobileLoginUp60Days = (patient: Patient) => {
  const lastMobileLogin = new Date(patient?.person.last_mobile_app_login)

  return differenceInCalendarDays(new Date(), lastMobileLogin) > 60
}

export const camelCase = (str: string) =>
  str
    .replace(/[^a-zA-Z0-9]+|_+/g, ' ')
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join('')

export const kebabCase = (str: string) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[^a-zA-Z0-9]+|_+/g, ' ')
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')

export const startCase = (str: string) =>
  str
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\s+/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

type KeyFn<T> = (item: T) => string

export const keyBy = <T>(arr: T[], keyFn: KeyFn<T>): Record<string, T> =>
  arr.reduce((result: Record<string, T>, item: T) => {
    const key = keyFn(item)
    result[key] = item
    return result
  }, {})

export const groupBy = <T>(arr: T[], keyFn: KeyFn<T>): Record<string, T[]> =>
  arr.reduce((result: Record<string, T[]>, item: T) => {
    const key = keyFn(item)
    result[key] = result[key] || []
    result[key].push(item)
    return result
  }, {})

export const uniqBy = <T>(arr: T[], keyFn: KeyFn<T>): T[] => {
  const seen = new Map()
  return arr.filter((item: T) => {
    const key = keyFn(item)
    const keep = !seen.has(key)
    if (keep) {
      seen.set(key, true)
    }
    return keep
  })
}

export const isEmpty = (obj: Record<string, any> | any[] | string): boolean => {
  if (Array.isArray(obj) || typeof obj === 'string') {
    return obj.length === 0
  }
  if (obj && typeof obj === 'object') {
    return Object.keys(obj).length === 0
  }
  return false
}

type Order = 'asc' | 'desc'

export const orderBy = <T>(
  array: ReadonlyArray<T>,
  keys: Array<keyof T | ((item: T) => any)>,
  orders: Order[] = ['asc'],
): T[] => {
  if (!array) {
    return []
  }

  return array.slice().sort((a, b) => {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const order = orders[i] || 'asc'

      const valueA = typeof key === 'function' ? key(a) : a[key]
      const valueB = typeof key === 'function' ? key(b) : b[key]

      let comparison = 0
      if (valueA > valueB) {
        comparison = 1
      } else if (valueA < valueB) {
        comparison = -1
      }

      if (order === 'desc') {
        comparison *= -1
      }

      if (comparison !== 0) return comparison
    }
    return 0
  })
}

export const isWithinNext24Hours = (timestamp: string) => {
  const now = new Date()
  const end = add(now, { hours: 24 })

  return isWithinInterval(new Date(timestamp), { start: now, end: end })
}
