import { SvgIcon, SvgIconProps } from '@mui/material'
import { FunctionComponent } from 'react'

const CloseIcon: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 40 40" {...props}>
    <path d="M14 14L26 26" stroke="currentColor" strokeWidth="1.5" />
    <path d="M26 14L14 26" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
)

export default CloseIcon
