export const environment = process.env.NEXT_PUBLIC_APP_STAGE

export const flags = {
  nashvilleCCRequired: process.env.NEXT_PUBLIC_NASHVILLE_CC_REQUIRED === 'true',
  availabilitiesTimeout:
    process.env.NEXT_PUBLIC_AVAILABILITIES_TIMEOUT === 'true',
  txPricing: process.env.NEXT_PUBLIC_TX_PRICING === 'true',
  salesforceChat: process.env.NEXT_PUBLIC_SALESFORCE_CHAT === 'true',
}

export const defaultRevalidateSeconds = Number(
  process.env.NEXT_PUBLIC_DEFAULT_REVALIDATE_SECONDS,
)
