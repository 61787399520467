const background = -1
const standard = 1
const section = 10
const header = 100
const videoControls = 1000
const popper = 2000

const zIndexes = {
  background,
  standard,
  section,
  header,
  videoControls,
  popper,
}

export default zIndexes
