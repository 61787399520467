import { GlobalStyles } from '@mui/material'
import Script from 'next/script'
import { flags } from 'lib/env'

// Declare the global variable to satisfy TypeScript
declare const embeddedservice_bootstrap: any

const SalesforceChatWidget = () => {
  const initEmbeddedMessaging = () => {
    try {
      embeddedservice_bootstrap.settings.language = 'en_US'
      embeddedservice_bootstrap.init(
        '00D2E000000pMOl',
        'Website',
        'https://hellotend.my.site.com/ESWWebsite1703012281697',
        {
          scrt2URL: 'https://hellotend.my.salesforce-scrt.com',
        },
      )
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err)
    }
  }

  return flags.salesforceChat ? (
    <>
      <GlobalStyles
        styles={{
          '#embedded-messaging #embeddedMessagingModalOverlay': {
            zIndex: 1200,
          },
          '#embedded-messaging #embeddedMessagingFrame': {
            zIndex: 1300,
          },
        }}
      />
      <Script
        src="https://hellotend.my.site.com/ESWWebsite1703012281697/assets/js/bootstrap.min.js"
        onLoad={initEmbeddedMessaging}
      />
    </>
  ) : null
}

export default SalesforceChatWidget
