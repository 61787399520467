import { createClient as contentfulCreateClient } from 'contentful'
import { environment } from './env'

type Space = 'marketing' | 'blog' | 'onboarding' | 'profile'

const config: {
  [key in Space]: {
    spaceId: string
    deliveryToken: string
    previewToken: string
    environment: string
  }
} = {
  marketing: {
    spaceId: process.env.NEXT_PUBLIC_CTF_SPACE_ID,
    deliveryToken: process.env.NEXT_PUBLIC_CTF_DELIVERY_TOKEN,
    previewToken: process.env.NEXT_PUBLIC_CTF_PREVIEW_TOKEN,
    environment: process.env.NEXT_PUBLIC_CTF_ENVIRONMENT,
  },
  blog: {
    spaceId: process.env.NEXT_PUBLIC_CTF_BLOG_SPACE_ID,
    deliveryToken: process.env.NEXT_PUBLIC_CTF_BLOG_DELIVERY_TOKEN,
    previewToken: process.env.NEXT_PUBLIC_CTF_BLOG_PREVIEW_TOKEN,
    environment: process.env.NEXT_PUBLIC_CTF_BLOG_ENVIRONMENT,
  },
  onboarding: {
    spaceId: process.env.NEXT_PUBLIC_CTF_OB_SPACE_ID,
    deliveryToken: process.env.NEXT_PUBLIC_CTF_OB_DELIVERY_TOKEN,
    previewToken: process.env.NEXT_PUBLIC_CTF_OB_PREVIEW_TOKEN,
    environment: process.env.NEXT_PUBLIC_CTF_OB_ENVIRONMENT,
  },
  profile: {
    spaceId: process.env.NEXT_PUBLIC_CTF_PROFILE_SPACE_ID,
    deliveryToken: process.env.NEXT_PUBLIC_CTF_PROFILE_DELIVERY_TOKEN,
    previewToken: process.env.NEXT_PUBLIC_CTF_PROFILE_PREVIEW_TOKEN,
    environment: process.env.NEXT_PUBLIC_CTF_PROFILE_ENV,
  },
}

const createClient = (space: Space = 'marketing') => {
  const options = {
    host: 'cdn.contentful.com',
    space: config[space].spaceId,
    accessToken: config[space].deliveryToken,
    environment: config[space].environment,
  }

  if (environment !== 'production') {
    options.host = 'preview.contentful.com'
    options.accessToken = config[space].previewToken
  }

  return contentfulCreateClient(options)
}

export const marketingClient = createClient('marketing')
export const blogClient = createClient('blog')
export const onboardingClient = createClient('onboarding')
export const profileClient = createClient('profile')
